.button_hero {
    border: 1px solid var(--secondary-2, #3FBE37);
    background: var(--secondary-2, #3FBE37);
    box-shadow: 0px 2px 32px 0px #74FFA3;
}

.nav-link {
    text-decoration: none;
    position: relative; /* To allow positioning the pseudo-element */
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
    opacity: 0; /* Start with the border invisible */
  }
  
  .nav-link:hover::after,
  .nav-link.active::after { /* Apply for active state */
    background-color: var(--secondary); /* Change the color on hover/active */
    opacity: 1; /* Make the border visible */
  }
  