.App {

}

h1 {
  font-weight: 600;
}

@keyframes fade {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


@keyframes left-to-middle {
  0%{
    transform: translateX(42%) scale(0.9);
  }
  100%{
    transform: translateX(100%) scale(1);
    z-index: 30;
  }
}
@keyframes middle-to-left {
  0%{
    transform: translateX(0) scale(1);
  }
  100%{
    transform: translateX(-58%) scale(0.9);
  }
}

@keyframes middle-to-right {
  0%{
    transform: translateX(0) scale(1);
  }
  100%{
    transform: translateX(58%) scale(0.9);
  }
}
@keyframes right-to-middle {
  0%{
    transform: translateX(-58%) scale(0.9);
  }
  100%{
    transform: translateX(-100%) scale(1);
    z-index: 30;
  }
}

@keyframes right-to-left {
  0%{
    transform: translateX(-42%) scale(0.9);
  }
  100%{
    transform: translateX(-158%) scale(0.9);
  }
}
@keyframes left-to-right {
  0%{
    transform: translateX(42%) scale(0.9);
  }
  100%{
    transform: translateX(158%) scale(0.9);
  }
}



 .right-animation[data-anim="0"]{
  animation: left-to-middle 500ms ease-in forwards;
}

.right-animation[data-anim="1"]{
  animation:  middle-to-right 500ms ease-in forwards;
}

.right-animation[data-anim="2"]{
  animation: right-to-left 500ms ease-in forwards;
}

.left-animation[data-anim="0"]{
  animation: left-to-right 500ms ease-in forwards;
}

.left-animation[data-anim="1"]{
  animation:  middle-to-left 500ms ease-in forwards;
}

.left-animation[data-anim="2"]{
  animation: right-to-middle 500ms ease-in forwards;
}
